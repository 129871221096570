// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-docs-casestudy-js": () => import("./../../../src/pages/docs/casestudy.js" /* webpackChunkName: "component---src-pages-docs-casestudy-js" */),
  "component---src-pages-docs-designingforenergy-js": () => import("./../../../src/pages/docs/designingforenergy.js" /* webpackChunkName: "component---src-pages-docs-designingforenergy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-technology-collection-js": () => import("./../../../src/pages/technology/collection.js" /* webpackChunkName: "component---src-pages-technology-collection-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-technology-recovery-js": () => import("./../../../src/pages/technology/recovery.js" /* webpackChunkName: "component---src-pages-technology-recovery-js" */),
  "component---src-pages-technology-storage-js": () => import("./../../../src/pages/technology/storage.js" /* webpackChunkName: "component---src-pages-technology-storage-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */)
}

